import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getIsPreviewStatus } from '../../state/app';
import { RichText } from '../richTextOptions';
import { Image } from './Common/Image';
// import { Tooltip } from './Common/Tooltip';

const SectionProviderComparisonDetail = (props) => {
     const { comparisonDetailFooterDescription, comparisonDetailTitle, linkReturnComparison, reimbursementType, isPreview } = props;

     let { primaryProvider, secondaryProvider } = props;

     if (isPreview) {
          primaryProvider = primaryProvider.fields;
          secondaryProvider = secondaryProvider.fields;
     }

     // const totalPotentialSavingsPrimary = primaryProvider.claimsExamples.reduce((acc, claim) => { return acc + claim.reimbursement; }, 0).toFixed(2)
     // const totalPotentialSavingsSecondary = secondaryProvider.claimsExamples.reduce((acc, claim) => { return acc + claim.reimbursement; }, 0).toFixed(2)

     const totalPotentialSavingsPrimary = primaryProvider.totalPotentialSavings;
     const totalPotentialSavingsSecondary = secondaryProvider.totalPotentialSavings;

     return (
          <section className="comparison-v2 detail">
               <div className="container">
                    <div className="headline">{comparisonDetailTitle && <RichText data={comparisonDetailTitle} />}</div>
                    <table>
                         <thead>
                              <tr>
                                   <th style={{ opacity: 0 }}>Comparison Detail Table</th>
                                   <th>
                                        {primaryProvider?.providerLogoMobile ? (
                                             <>
                                                  {primaryProvider?.providerLogo ? (
                                                       <>
                                                            <Image image={primaryProvider.providerLogo} cla="sl-desktop" />
                                                            <Image image={primaryProvider.providerLogoMobile} cla="sl-mobile" />
                                                       </>
                                                  ) : (
                                                       primaryProvider?.providerName
                                                  )}
                                             </>
                                        ) : primaryProvider?.providerLogo ? (
                                             <Image image={primaryProvider.providerLogo} />
                                        ) : (
                                             primaryProvider?.providerName
                                        )}
                                   </th>
                                   <th>
                                        {secondaryProvider?.providerLogoMobile ? (
                                             <>
                                                  {secondaryProvider?.providerLogo ? (
                                                       <>
                                                            <Image image={secondaryProvider.providerLogo} cla="sl-desktop" />
                                                            <Image image={secondaryProvider.providerLogoMobile} cla="sl-mobile" />
                                                       </>
                                                  ) : (
                                                       secondaryProvider?.providerName
                                                  )}
                                             </>
                                        ) : secondaryProvider?.providerLogo ? (
                                             <Image image={secondaryProvider.providerLogo} />
                                        ) : (
                                             secondaryProvider?.providerName
                                        )}
                                   </th>
                              </tr>
                         </thead>
                         <tbody>
                              <tr>
                                   <td colSpan="3">Coverage and pricing</td>
                              </tr>
                              <tr>
                                   <td>
                                        Popularity among pet owners<sup>1</sup>
                                   </td>
                                   <td>{primaryProvider?.popularityAmongPetOwners && primaryProvider.popularityAmongPetOwners}</td>
                                   <td>{secondaryProvider?.popularityAmongPetOwners && secondaryProvider.popularityAmongPetOwners}</td>
                              </tr>
                              <tr>
                                   <td>Plan name</td>
                                   <td>{primaryProvider?.planName && <RichText data={primaryProvider.planName}></RichText>}</td>
                                   <td>{secondaryProvider?.planName && <RichText data={secondaryProvider.planName}></RichText>}</td>
                              </tr>
                              <tr>
                                   <td>Base monthly premium</td>
                                   <td>{primaryProvider?.baseMonthlyPremium && <span> {primaryProvider.baseMonthlyPremium}</span>}</td>
                                   <td>{secondaryProvider?.baseMonthlyPremium && <span> {secondaryProvider.baseMonthlyPremium}</span>}</td>
                              </tr>
                              <tr>
                                   <td>Wellness rider premium</td>
                                   <td>{primaryProvider?.wellnessRiderPremium && <span> {primaryProvider.wellnessRiderPremium}</span>}</td>
                                   <td>{secondaryProvider?.wellnessRiderPremium && <span> {secondaryProvider.wellnessRiderPremium}</span>}</td>
                              </tr>
                              <tr>
                                   <td>Additional rider premium(s)</td>
                                   <td>{primaryProvider?.additionalRiderPremiums && <RichText data={primaryProvider.additionalRiderPremiums}></RichText>}</td>
                                   <td>{secondaryProvider?.additionalRiderPremiums && <RichText data={secondaryProvider.additionalRiderPremiums}></RichText>}</td>
                              </tr>
                              <tr>
                                   <td>
                                        Monthly premium<sup>2</sup>
                                   </td>
                                   <td>{primaryProvider?.monthlyPremium && <RichText data={primaryProvider.monthlyPremium}></RichText>}</td>
                                   <td>{secondaryProvider?.monthlyPremium && <RichText data={secondaryProvider.monthlyPremium}></RichText>}</td>
                              </tr>
                              <tr>
                                   <td>Deductible</td>
                                   <td>
                                        {primaryProvider?.deductible && (
                                             <span>
                                                  {/* {
                                                primaryProvider.deductibleTooltip ? <Tooltip content={primaryProvider.deductibleTooltip} action="click" position="right" labelClick={`${"$" + primaryProvider.deductible.toLocaleString('en-us')}`} /> : (
                                                    `${"$" + primaryProvider.deductible.toLocaleString('en-us')}`
                                                )
                                            } */}
                                                  {/* {"$" + primaryProvider.deductible.toLocaleString('en-us')} */}

                                                  <RichText data={primaryProvider.deductible}></RichText>
                                             </span>
                                        )}
                                   </td>
                                   <td>
                                        {secondaryProvider?.deductible && (
                                             <span>
                                                  {/* {
                                                secondaryProvider.deductibleTooltip ? <Tooltip content={secondaryProvider.deductibleTooltip} action="click" position="right" labelClick={`${"$" + secondaryProvider.deductible.toLocaleString('en-us')}`} /> : (
                                                    `${"$" + secondaryProvider.deductible.toLocaleString('en-us')}`
                                                )
                                            } */}
                                                  {/* {"$" + secondaryProvider.deductible.toLocaleString('en-us')} */}

                                                  <RichText data={secondaryProvider.deductible}></RichText>
                                             </span>
                                        )}
                                   </td>
                              </tr>
                              <tr>
                                   <td>Reimbursement type</td>
                                   <td>{primaryProvider?.reimbursementType && <RichText data={primaryProvider.reimbursementType}></RichText>}</td>
                                   <td>{secondaryProvider?.reimbursementType && <RichText data={secondaryProvider.reimbursementType}></RichText>}</td>
                              </tr>
                              {/* <tr>
                                   <td>Accident coverage</td>
                                   <td>{primaryProvider?.accidentCoverage && primaryProvider.accidentCoverage}</td>
                                   <td>{secondaryProvider?.accidentCoverage && secondaryProvider.accidentCoverage}</td>
                              </tr>
                              <tr>
                                   <td>Illness coverage</td>
                                   <td>{primaryProvider?.illnessCoverage && primaryProvider.illnessCoverage}</td>
                                   <td>{secondaryProvider?.illnessCoverage && secondaryProvider.illnessCoverage}</td>
                              </tr>
                              <tr className="hereditary">
                                   <td>Hereditary & congenital coverage</td>
                                   <td>{primaryProvider?.hereditaryCongenitalCoverage && primaryProvider.hereditaryCongenitalCoverage}</td>
                                   <td>{secondaryProvider?.hereditaryCongenitalCoverage && secondaryProvider.hereditaryCongenitalCoverage}</td>
                              </tr>
                              <tr className="fee">
                                   <td>Additional fees</td>
                                   <td>
                                        {primaryProvider?.additionalFees && <span>${primaryProvider.additionalFees}</span>}
                                        {primaryProvider?.additionalFeesText && <span>{primaryProvider.additionalFeesText}</span>}
                                   </td>
                                   <td>
                                        {secondaryProvider?.additionalFees && <span>${secondaryProvider.additionalFees}</span>}
                                        {secondaryProvider?.additionalFeesText && <span>{secondaryProvider.additionalFeesText}</span>}
                                   </td>
                              </tr> */}
                              <tr>
                                   <td colSpan="3">Coverage features</td>
                              </tr>
                              <tr>
                                   <td>Telehealth - 24/7 access to veterinary experts</td>
                                   <td>{primaryProvider?.telehealth247Access && primaryProvider.telehealth247Access}</td>
                                   <td>{secondaryProvider?.telehealth247Access && secondaryProvider.telehealth247Access}</td>
                              </tr>
                              <tr>
                                   <td>Use any veterinarian, emergency clinic or specialist</td>
                                   <td>{primaryProvider?.useAnyVeterinarian && primaryProvider.useAnyVeterinarian}</td>
                                   <td>{secondaryProvider?.useAnyVeterinarian && secondaryProvider.useAnyVeterinarian}</td>
                              </tr>
                              <tr>
                                   <td>Exams</td>
                                   <td>{primaryProvider?.exams && primaryProvider.exams}</td>
                                   <td>{secondaryProvider?.exams && secondaryProvider.exams}</td>
                              </tr>
                              <tr>
                                   <td>Wellness coverage</td>
                                   <td>{primaryProvider?.wellnessCoverage && primaryProvider.wellnessCoverage}</td>
                                   <td>{secondaryProvider?.wellnessCoverage && secondaryProvider.wellnessCoverage}</td>
                              </tr>
                              <tr>
                                   <td>Accidents and illnesses, including hospitalizations and surgeries</td>
                                   <td>{primaryProvider?.accidentsAndIllnesses && <RichText data={primaryProvider.accidentsAndIllnesses}></RichText>}</td>
                                   <td>{secondaryProvider?.accidentsAndIllnesses && <RichText data={secondaryProvider.accidentsAndIllnesses}></RichText>}</td>
                              </tr>
                              <tr>
                                   <td>Chronic and ongoing diseases (example: allergies)</td>
                                   <td>{primaryProvider?.chronicAndOngoingDiseasesExampleAllergies && primaryProvider.chronicAndOngoingDiseasesExampleAllergies}</td>
                                   <td>{secondaryProvider?.chronicAndOngoingDiseasesExampleAllergies && secondaryProvider.chronicAndOngoingDiseasesExampleAllergies}</td>
                              </tr>
                              <tr>
                                   <td>Cancer</td>
                                   <td>{primaryProvider?.cancer && primaryProvider.cancer}</td>
                                   <td>{secondaryProvider?.cancer && secondaryProvider.cancer}</td>
                              </tr>
                              <tr>
                                   <td>Congential and hereditary</td>
                                   <td>{primaryProvider?.congentialAndHereditary && primaryProvider.congentialAndHereditary}</td>
                                   <td>{secondaryProvider?.congentialAndHereditary && secondaryProvider.congentialAndHereditary}</td>
                              </tr>
                              <tr>
                                   <td>Dental surgeries and diseases</td>
                                   <td>
                                        {/* {
                                    primaryProvider?.dentalSurgeriesAndDiseases && (
                                        primaryProvider.dentalSurgeriesAndDiseasesTooltip ? <Tooltip content={primaryProvider.dentalSurgeriesAndDiseasesTooltip} action="click" position="right" labelClick={`${primaryProvider.dentalSurgeriesAndDiseases}`} /> : (
                                            `${primaryProvider.dentalSurgeriesAndDiseases}`
                                        )
                                    )
                                } */}

                                        {primaryProvider?.dentalSurgeriesAndDiseases && <RichText data={primaryProvider.dentalSurgeriesAndDiseases}></RichText>}
                                   </td>
                                   <td>
                                        {/* {
                                    secondaryProvider?.dentalSurgeriesAndDiseases && (
                                        secondaryProvider.dentalSurgeriesAndDiseasesTooltip ? <Tooltip content={secondaryProvider.dentalSurgeriesAndDiseasesTooltip} action="click" position="right" labelClick={`${secondaryProvider.dentalSurgeriesAndDiseases}`} /> : (
                                            `${secondaryProvider.dentalSurgeriesAndDiseases}`
                                        )
                                    )
                                } */}

                                        {secondaryProvider?.dentalSurgeriesAndDiseases && <RichText data={secondaryProvider.dentalSurgeriesAndDiseases}></RichText>}
                                   </td>
                              </tr>
                              <tr>
                                   <td>Prescription medications</td>
                                   <td>{primaryProvider?.prescriptionMedications && primaryProvider.prescriptionMedications}</td>
                                   <td>{secondaryProvider?.prescriptionMedications && secondaryProvider.prescriptionMedications}</td>
                              </tr>
                              <tr>
                                   <td>Prescription food</td>
                                   <td>
                                        {/* {
                                    primaryProvider?.prescriptionFoodAndSupplements && (
                                        primaryProvider.prescriptionFoodAndSupplementsTooltip ? <Tooltip content={primaryProvider.prescriptionFoodAndSupplementsTooltip} action="click" position="right" labelClick={`${primaryProvider.prescriptionFoodAndSupplements}`} /> : (
                                            `${primaryProvider.prescriptionFoodAndSupplements}`
                                        )
                                    )
                                } */}
                                        {primaryProvider.prescriptionFoodAndSupplements && <RichText data={primaryProvider.prescriptionFoodAndSupplements}></RichText>}
                                   </td>
                                   <td>
                                        {/* {
                                    secondaryProvider?.prescriptionFoodAndSupplements && (
                                        secondaryProvider.prescriptionFoodAndSupplementsTooltip ? <Tooltip content={secondaryProvider.prescriptionFoodAndSupplementsTooltip} action="click" position="right" labelClick={`${secondaryProvider.prescriptionFoodAndSupplements}`} /> : (
                                            `${secondaryProvider.prescriptionFoodAndSupplements}`
                                        )
                                    )
                                } */}
                                        {secondaryProvider.prescriptionFoodAndSupplements && <RichText data={secondaryProvider.prescriptionFoodAndSupplements}></RichText>}
                                   </td>
                              </tr>
                              <tr>
                                   <td>Prescribed supplements</td>
                                   <td>{primaryProvider?.prescribedSupplements && primaryProvider.prescribedSupplements}</td>
                                   <td>{secondaryProvider?.prescribedSupplements && secondaryProvider.prescribedSupplements}</td>
                              </tr>
                              <tr>
                                   <td>Herbal supplements</td>
                                   <td>{primaryProvider?.herbalSupplements && <RichText data={primaryProvider.herbalSupplements}></RichText>}</td>
                                   <td>{secondaryProvider?.herbalSupplements && <RichText data={secondaryProvider.herbalSupplements}></RichText>}</td>
                              </tr>
                              <tr>
                                   <td>Alternative/holistic therapy</td>
                                   <td>{primaryProvider?.alternativeholisticTherapy && <RichText data={primaryProvider.alternativeholisticTherapy}></RichText>}</td>
                                   <td>{secondaryProvider?.alternativeholisticTherapy && <RichText data={secondaryProvider.alternativeholisticTherapy}></RichText>}</td>
                              </tr>
                              <tr>
                                   <td>Behavioral coverage</td>
                                   <td>{primaryProvider?.behavioralCoverage && primaryProvider.behavioralCoverage}</td>
                                   <td>{secondaryProvider?.behavioralCoverage && secondaryProvider.behavioralCoverage}</td>
                              </tr>
                              <tr>
                                   <td>Annual exam requirement</td>
                                   <td>{primaryProvider?.annualExamRequirement && primaryProvider.annualExamRequirement}</td>
                                   <td>{secondaryProvider?.annualExamRequirement && secondaryProvider.annualExamRequirement}</td>
                              </tr>

                              {/* <tr>
                                   <td>Customized plans by coverage-specific annual maximums and coverage types</td>
                                   <td>{primaryProvider?.customizedPlans && primaryProvider.customizedPlans}</td>
                                   <td>{secondaryProvider?.customizedPlans && secondaryProvider.customizedPlans}</td>
                              </tr>
                              <tr>
                                   <td>Pet prescription discounts</td>
                                   <td>{primaryProvider?.petPrescriptionDiscounts && primaryProvider.petPrescriptionDiscounts}</td>
                                   <td>{secondaryProvider?.petPrescriptionDiscounts && secondaryProvider.petPrescriptionDiscounts}</td>
                              </tr>
                              <tr>
                                   <td>Underwriter</td>
                                   <td>{primaryProvider?.underwriter && primaryProvider.underwriter}</td>
                                   <td>{secondaryProvider?.underwriter && secondaryProvider.underwriter}</td>
                              </tr> */}
                              <tr>
                                   <td colSpan="3">Claims examples</td>
                              </tr>

                              {reimbursementType?.map((reimbursement, i) => {
                                   if (isPreview) {
                                        reimbursement = reimbursement.fields;
                                   }

                                   const getPercentage = (claimsExamples, reimbursement) => {
                                        let percentage = 0;

                                        claimsExamples?.some((claim, i) => {
                                             let claimReimbursementName;

                                             if (isPreview) {
                                                  claim = claim.fields;
                                                  claimReimbursementName = claim.reimbursementType.fields.reimbursementName;
                                             } else {
                                                  claimReimbursementName = claim.reimbursementType.reimbursementName;
                                             }

                                             if (claimReimbursementName === reimbursement.reimbursementName) {
                                                  percentage = Math.round((claim.reimbursement * 100) / claim.vetBill);
                                                  return true;
                                             }

                                             return false;
                                        });

                                        return percentage;
                                   };

                                   return (
                                        <React.Fragment key={i}>
                                             <tr>
                                                  <td>{reimbursement.title && <RichText data={reimbursement.title} />}</td>
                                                  <td>
                                                       {primaryProvider?.claimsExamples?.map((claim, i) => {
                                                            let claimReimbursementName;

                                                            if (isPreview) {
                                                                 claim = claim.fields;
                                                                 claimReimbursementName = claim.reimbursementType.fields.reimbursementName;
                                                            } else {
                                                                 claimReimbursementName = claim.reimbursementType.reimbursementName;
                                                            }

                                                            if (claimReimbursementName === reimbursement.reimbursementName) {
                                                                 return (
                                                                      <React.Fragment key={i}>
                                                                           {/* <span>${claim?.reimbursement && claim.reimbursement.toLocaleString('en-us')}</span> */}
                                                                           <span>
                                                                                $
                                                                                {claim?.reimbursement &&
                                                                                     claim.reimbursement.toLocaleString('en-us', {
                                                                                          minimumFractionDigits: 2,
                                                                                          maximumFractionDigits: 2
                                                                                     })}
                                                                           </span>

                                                                           <span className="d-block">reimbursement</span>
                                                                      </React.Fragment>
                                                                 );
                                                            }
                                                       })}
                                                  </td>
                                                  <td>
                                                       {secondaryProvider?.claimsExamples?.map((claim, i) => {
                                                            let claimReimbursementName;

                                                            if (isPreview) {
                                                                 claim = claim.fields;
                                                                 claimReimbursementName = claim.reimbursementType.fields.reimbursementName;
                                                            } else {
                                                                 claimReimbursementName = claim.reimbursementType.reimbursementName;
                                                            }

                                                            if (claimReimbursementName === reimbursement.reimbursementName) {
                                                                 return (
                                                                      <React.Fragment key={i}>
                                                                           {/* <span>${claim?.reimbursement && claim.reimbursement.toLocaleString('en-us')}</span> */}
                                                                           <span>
                                                                                $
                                                                                {typeof claim?.reimbursement === 'number' &&
                                                                                     claim.reimbursement.toLocaleString('en-us', {
                                                                                          minimumFractionDigits: 2,
                                                                                          maximumFractionDigits: 2
                                                                                     })}
                                                                           </span>
                                                                           <span className="d-block">reimbursement</span>
                                                                      </React.Fragment>
                                                                 );
                                                            }
                                                       })}
                                                  </td>
                                             </tr>
                                             <tr>
                                                  <td>Percentage of reimbursement</td>
                                                  <td>{getPercentage(primaryProvider?.claimsExamples, reimbursement)}%</td>
                                                  <td>{getPercentage(secondaryProvider?.claimsExamples, reimbursement)}%</td>
                                             </tr>
                                        </React.Fragment>
                                   );
                              })}
                              <tr>
                                   <td>Total potential savings</td>
                                   <td>
                                        {/* <span>${parseFloat(totalPotentialSavingsPrimary).toLocaleString('en-us')}</span> */}
                                        <span>${totalPotentialSavingsPrimary.toLocaleString('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>

                                        <span className="d-block">reimbursement</span>
                                   </td>
                                   <td>
                                        {/* <span>${parseFloat(totalPotentialSavingsSecondary).toLocaleString('en-us')}</span> */}
                                        <span>${totalPotentialSavingsSecondary.toLocaleString('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>

                                        <span className="d-block">reimbursement</span>
                                   </td>
                              </tr>
                              <tr>
                                   <td>Best value</td>
                                   <td>
                                        {primaryProvider?.bestValue === 'Yes' ? (
                                             <img loading="lazy" src="/images/icons/icon-check-comparison.svg" alt="icon check" />
                                        ) : primaryProvider?.bestValue === 'No' ? (
                                             <img loading="lazy" src="/images/icons/icon-uncheck-comparison.svg" alt="icon uncheck" />
                                        ) : (
                                             <RichText data={primaryProvider.bestValueRichText}></RichText>
                                        )}
                                   </td>
                                   <td>{linkReturnComparison && <RichText data={linkReturnComparison}></RichText>}</td>
                              </tr>
                         </tbody>
                    </table>
                    {comparisonDetailFooterDescription && (
                         <div className="footer-description">
                              <RichText data={comparisonDetailFooterDescription} />
                         </div>
                    )}
               </div>
          </section>
     );
};

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}

SectionProviderComparisonDetail.propTypes = {
     isPreview: PropTypes.bool
};
const ContentfulSectionProviderComparisonDetail = connect(mapStateToProps)(SectionProviderComparisonDetail);

export default ContentfulSectionProviderComparisonDetail;
